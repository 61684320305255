import React, { useState } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';
import logo from '../assets/img/logo.png'; // Import your logo image

const ServicesPage = () => {
  const [activeService, setActiveService] = useState(null);

  const toggleService = (service) => {
    setActiveService((prev) => (prev === service ? null : service));
  };

  const services = [
    {
      name: 'ONLY US - $450 AUD',
      description: `‘ONLY US’ ceremony – Legal requirements only, with two witnesses and camera person. $450 (45 minutes max)
My promise and commitment to ensure you ceremony is personal, professionally prepared and presented.
- The initial appointment when you lodge your Notice of Intended Marriage (NOIM) with me, pay the booking fee and we start planning your day.
- All legal paperwork, ‘before and after’ information will be provided to you, handled and lodged promptly.
- One initial meeting then unlimited phone and email consultations.
- Just the essential wording to legalise the marriage.
- Celebration Marriage Certificate will be presented to you on your wedding day.
- Ceremony will be conducted within your residential area or nearby OR travel costs will be negotiated at the time of booking.`,
    },
    {
      name: 'US and BELOVED ONES - $670 AUD',
      description: `‘US and BELOVED ONES’ ceremony – option for an intimate gathering with under 30 guests. $670 (1-hour max)
ALL ‘ONLY US - Legal requirements only’ inclusions
PLUS:
- A detailed ceremony script will be done to your personal wishes and requirements.
- Provision of my own professional PA system so your ceremony is audible.
- Signing table and chairs.
- Music played during the ceremony – one song with lyrics and one song without lyrics.
- Travel costs will be negotiated at the time of booking.`,
    },
    {
      name: 'THE WEDDING THAT WE LOVE - $970 AUD',
      description: `‘THE WEDDING THAT WE LOVE’ ceremony – $970
Full wedding package with all the ‘bells and whistles’.
- All ‘ONLY US - Legal requirements only’ inclusions.
- One initial meeting, one rehearsal meeting at your chosen venue as well as unlimited phone and email consultations during the preparation process.
- Resource booklets for preparation provided.
- A detailed ceremony script will be done to your personal wishes and requirements.
- Copy of your vows in a presentation folder.
- Music played during the ceremony – two songs with lyrics and one song without lyrics.
- Provision of my own professional PA system so your ceremony is audible.
- Signing table and chairs.
- Travel costs will be negotiated at the time of booking.`,
    },
    {
      name: 'WE ARE COMMITTED - $590 AUD',
      description: `‘WE ARE COMMITTED’ package - $590 (45 minutes max)
- My promise and commitment to ensure you ceremony is personal, professionally prepared and presented.
- The initial appointment as you share with me your story and visions for the day, pay the booking fee and we start planning your day.
- One initial meeting then unlimited phone and email consultations.
- Provision of my own professional PA system so your ceremony is audible.
- Music played during the ceremony – two songs.
- Resource booklets for preparation provided.
- A detailed ceremony script will be done to your personal wishes and requirements.
- Ceremony will be conducted within your residential area or nearby OR travel costs will be negotiated at the time of booking.
*NO legal paperwork will be provided as this is NOT a legally binding wedding ceremony*`,
    },
    {
      name: 'RENEW OUR LOVE - $590 AUD',
      description: `‘RENEW OUR LOVE’ package – option for any couples who wish to renew their vows- $590 (45 minutes max)
- My promise and commitment to ensure you ceremony is personal, professionally prepared and presented.
- One initial appointment as you share with me your ideas for the day, pay the booking fee and we start planning your day.
- Unlimited phone and email consultations.
- Music played during the ceremony – two songs.
- Copy of your vows in a presentation folder.
- Provision of my own professional PA system so your ceremony is audible.
- A detailed ceremony script will be done to your personal wishes and requirements.
- Ceremony will be conducted within your residential area or nearby OR travel costs will be negotiated at the time of booking.`,
    },
    {
      name: 'Refund Policy',
      description: `Refund:
- Postponement by the client:
  The Notice of Intended Marriage is active for 18 months so the deposit will be held over to the new date (if the new date is available). You will be responsible for all other costs involved with postponement including but not limited to the ceremony venue. New schedule of fees may be required based on costs during this time.
- Cancellation by the client:
  If the ceremony is cancelled, no later than 14 days prior to the event, any funds paid (except the deposit) by you will be refunded in full. In the case of air travel or accommodation costs, this will only be refunded if refundable options were selected when booking. In the case of non-refundable options, these unfortunately cannot be refunded.
  If the ceremony is cancelled within 14 days of the event, the celebrant may refund fees paid by you (except the deposit), depending on the circumstances. A refund in this regard is at the discretion of the celebrant.
- Cancellation by celebrant:
  In the event that the celebrant is unable to attend an event, 100% of funds paid by you will be refunded, including the $150 deposit.`,
    },
  ];

  const ServicesContainer = styled.div`
    background-color: #fce4ec; /* Light pink background */
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    font-family: Lucida;
  `;

  const Logo = styled.img`
    width: 200px; /* Adjust size as needed */
    height: auto;
    margin-bottom: 2rem; /* Add some space below the logo */
  `;

  const Content = styled.div`
    max-width: 800px;
    text-align: center;
    font-family: Lucida;
  `;

  const Heading = styled.h1`
    font-size: 2.5rem;
    color: #8c3e56; /* Dark pink heading */
    margin-bottom: 1.5rem;
    font-family: Lucida;
  `;

  const ServiceList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: Lucida;
  `;

  const ServiceItem = styled.li`
    font-size: 1.2rem;
    color: #4d4d4d; /* Dark gray paragraph text */
    margin-bottom: 1rem;
    cursor: pointer;
    font-family: Lucida;

    &:hover {
      color: #8c3e56;
    }
  `;

  const Description = styled(animated.div)`
    font-size: 1rem;
    color: #4d4d4d; /* Dark gray paragraph text */
    margin-top: 0.5rem;
    padding: 0 1rem;
    overflow: hidden;
    white-space: pre-wrap;
    font-family: Lucida;
  `;

  const Service = ({ service, isActive, toggleService }) => {
    const animationProps = useSpring({
      maxHeight: isActive ? 500 : 0, // Adjust maxHeight based on content
      opacity: isActive ? 1 : 0,
      config: { tension: 250, friction: 30 },
    });

    return (
      <React.Fragment>
        <ServiceItem onClick={() => toggleService(service.name)}>
          {service.name}
        </ServiceItem>
        <Description style={animationProps}>
          <div style={{ padding: isActive ? '1rem' : '0rem' }}>
            {service.description}
          </div>
        </Description>
      </React.Fragment>
    );
  };

  return (
    <ServicesContainer>
      <Logo src={logo} alt="Logo" />
      <Content>
        <Heading>Our Services</Heading>
        <ServiceList>
          {services.map((service, index) => (
            <Service
              key={index}
              service={service}
              isActive={activeService === service.name}
              toggleService={toggleService}
            />
          ))}
        </ServiceList>
      </Content>
    </ServicesContainer>
  );
};

export default ServicesPage;
