import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.png';

const NavbarContainer = styled.nav`
  background-color: #D7A790;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  /* Media Query for mobile */
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
  }
`;

const Logo = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  font-weight: bold;
  color: #8c3e56;
  text-decoration: none;
  font-family: Lucida;

  img {
    margin-right: 0.5rem;
  }
`;

const NavLinks = styled.div`
  display: flex;
  gap: 2rem;

  /* Media Query for mobile */
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1rem;
    display: ${(props) => (props.menuOpen ? 'flex' : 'none')};
  }
`;

const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  position: relative;

  &:after {
    content: '';
    display: block;
    height: 2px;
    width: 0;
    background: #fff;
    transition: width 0.3s;
    position: absolute;
    bottom: -3px;
    left: 0;
  }

  &:hover:after {
    width: 100%;
  }
`;

const Button = styled(Link)`
  background-color: #fff;
  color: #f0c040;
  padding: 0.5rem 1rem;
  border-radius: 25px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: #f0c040;
    color: #fff;
  }
`;

const HamburgerIcon = styled.div`
  display: none; /* Initially hidden on larger screens */
  cursor: pointer;

  /* Media Query for mobile */
  @media (max-width: 768px) {
    display: block;
  }
`;

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <NavbarContainer>
      <Logo to="/">
        <img src={logo} alt="Logo" width="40" height="40" /> Unforgettable memories with Vivian
      </Logo>
      <HamburgerIcon onClick={toggleMenu}>☰</HamburgerIcon>
      <NavLinks menuOpen={menuOpen}>
        <NavLink to="/" onClick={toggleMenu}>Home</NavLink>
        <NavLink to="/about" onClick={toggleMenu}>About</NavLink>
        <NavLink to="/services" onClick={toggleMenu}>Services</NavLink>
        <NavLink to="/contact" onClick={toggleMenu}>Contact</NavLink>
      </NavLinks>
      <Button to="/services">Get Started</Button>
    </NavbarContainer>
  );
};

export default Navbar;
