import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import logo from '../assets/img/logo.png'; // Correctly import the image


const NavLink = styled(Link)`
  color: #fff;
  text-decoration: none;
  font-size: 1rem;
  position: relative;

  &:after {
    content: '';
    display: block;
    height: 2px;
    width: 0;
    background: #fff;
    transition: width 0.3s;
    position: absolute;
    bottom: -3px;
    left: 0;
  }

  &:hover:after {
    width: 100%;
  }
`;

const FooterContainer = styled.footer`
  background-color: #D7A790;
  padding: 1.5rem 1rem; /* Adjusted padding */
  display: flex;
  flex-direction: column; /* Changed to column layout */
  justify-content: center;
  align-items: center;
  box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center align text */
`;

const FooterContent = styled.div`
  color: white;
`;

const FooterText = styled.p`
  font-size: 0.9rem;
  margin: 0.5rem 0;
`;

const Logo = styled.img`
  margin-top: 1rem; /* Added margin */
  width: 80px; /* Adjusted width */
  height: 80px; /* Adjusted height */
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column; /* Changed to column layout */
  align-items: center;
`;

const ContactDetails = styled.div`
  margin-top: 0.5rem; /* Added margin */
`;

const ContactLink = styled.a`
  color: white;
  text-decoration: none;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterContent>
        <FooterText>
          *We offer services all around Australia. Transport is at the client’s expense if we are not in your location.
        </FooterText>
      </FooterContent>
      <ContactInfo>
        <NavLink to="/tos">Terms of Service</NavLink>
        <NavLink to="/feedback">Feedback</NavLink>
        <ContactDetails>
          <ContactLink href="mailto:vivianofficialcelebrant@gmail.com">vivianofficialcelebrant@gmail.com</ContactLink>
          <FooterText>+61 410 821 940</FooterText>
        </ContactDetails>
        <Logo src={logo} alt="Logo" />
      </ContactInfo>
      <FooterContent>
        Website designed and devloped by <NavLink to="https://bonniciwebservices.com.au">Nate of Bonnici Web Services </NavLink>
      </FooterContent>

    </FooterContainer>
  );
};

export default Footer;
