import React from 'react';
import styled from 'styled-components';

const BusinessPoliciesPage = () => {
    const PageContainer = styled.div`
        background-color: #fce4ec; /* Light pink background */
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 2rem;
    `;

    const Section = styled.section`
        max-width: 800px;
        margin-bottom: 2rem;
        padding: 1.5rem;
        background-color: #fff; /* White background for sections */
        border-radius: 10px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Shadow effect */
    `;

    const Title = styled.h2`
        text-align: center;
        font-size: 1.5rem;
        color: #8c3e56; /* Dark pink heading */
        margin-bottom: 1rem;
        font-family: Lucida;
        justify-content: center;
    `;

    const List = styled.ul`
        padding-left: 1rem;
    `;

    const ListItem = styled.li`
        font-size: 1rem;
        color: #4d4d4d; /* Dark gray paragraph text */
        line-height: 1.6;
        margin-bottom: 0.5rem;
        list-style-type: "\u2740";
    `;

    return (
        <PageContainer>
            <Section>
                <Title>Operation Processes and Procedures</Title>
                <List>
                    <ListItem>
                        The Celebrant will provide the Client with information and guidance to enable them to choose or compose a ceremony that will meet their needs and expectations.
                    </ListItem>
                    <ListItem>
                        The Celebrant will ensure that their own personal presentation is of an appropriate standard at all times.
                    </ListItem>
                    <ListItem>
                        The first meeting will be held at a mutually convenient location, ideally a respectable café or restaurant, which will provide a relaxing atmosphere as well as a certain level of privacy.
                    </ListItem>
                    <ListItem>
                        The Celebrant will provide or suggest appropriate facilities to meet with clients.
                    </ListItem>
                    <ListItem>
                        All reference material TEMPLATES used by the Celebrant to create and conduct ceremonies shall be reviewed and updated on a regular basis or as required, with no less than twice yearly.
                    </ListItem>
                    <ListItem>
                        The Celebrant will ensure they are familiar with the location of the ceremony and the time taken to get there and if necessary, complete a survey of the event location. The client may be required to assist with gaining access etc.
                    </ListItem>
                    <ListItem>
                        The Celebrant will confirm pronunciation of names and or phrases that are to be used in the ceremony to ensure correct and proper usage.
                    </ListItem>
                    <ListItem>
                        The Celebrant will not reproduce or use any images of the Clients ceremony without the Clients’ approval.
                    </ListItem>
                    <ListItem>
                        The Celebrant will provide a selection of ceremony package options to the Client however these may be adjusted to suit the Client needs and will be subject to quote.
                    </ListItem>
                    <ListItem>
                        The Celebrant commits to travelling to the ceremony in a manner that ensures a safe and prompt arrival. However, in the event of an accident where the Celebrant is not conscious or able to talk, contact details of a nominated person of the Clients choice will be clearly and readily available in an A4 plastic sleeve, for emergency services to be able to contact.
                    </ListItem>
                    <ListItem>
                        A booking is not confirmed until the Client has paid a deposit as outlined under ‘Invoicing and Refunds’ in this document and receives confirmation of the booking from the Celebrant.
                    </ListItem>
                </List>
            </Section>

            <Section>
                <Title>Privacy and Confidentiality</Title>
                <List>
                    <ListItem>
                        The Celebrant is committed to ensuring that all personal information is only collected, disclosed, used and stored in accordance with the Privacy Act 1988 and the Australian Privacy Principles.
                    </ListItem>
                    <ListItem>
                        Only personally identifiable information required for purposes relevant to the legal solemnisation of your marriage (or to the conduct of your other ceremony) will be collected. These purposes will be clearly explained to you in advance.
                    </ListItem>
                    <ListItem>
                        Your information will not be passed on to a third party, except with your express written permission (for example, to another agreed celebrant in the event that I am unable to attend your ceremony), or where required by law (e.g. to the Registrar of Births, Deaths and Marriages).
                    </ListItem>
                    <ListItem>
                        Your feedback and photographs will only be used in promotional materials with your written consent.
                    </ListItem>
                    <ListItem>
                        You reserve the right to check the accuracy of your personal information as retained on my records, and to have any necessary amendments made at your request.
                    </ListItem>
                </List>
            </Section>

            <Section>
                <Title>Invoicing and Refunds</Title>
                <List>
                    <ListItem> A non-refundable deposit of $150.00 is payable to secure a booking. This deposit must be paid within 7 days of the invoice issue date. If the payment is not made after this period, your booking will no longer be held or confirmed.</ListItem>
                </List>
                <Section>
                    <Title>Payments</Title>
                    <List>
                        <ListItem> All payments are to be made via direct deposit. The Celebrant's bank details can be found on the invoice. The final payment must be received no later than 14 days before the ceremony date. The Celebrant retains the right to refuse the provision of services for failure to pay the full balance of the agreed package.</ListItem>
                        <ListItem> By paying your booking fee, you are agreeing to the terms and conditions outlined in the ‘Business policies and procedures’ section</ListItem>
                    </List>
                </Section>
                <Section>
                    <Title>Refunds</Title>
                    <List>
                        <ListItem>Postponement by the client: The Notice of Intended Marriage is active for 18 months so the deposit will be held over to the new date (if the new date is available). You will be responsible for all other costs involved with postponement including but not limited to the ceremony venue. A new schedule of fees may be required based on costs during this time</ListItem>
                        <ListItem>Cancellation by the client: If the ceremony is cancelled, no later than 14 days prior to the event, any funds paid (except the deposit) by you will be refunded in full. In the case of air travel or accommodation costs, this will only be refunded if refundable options were selected when booking. In the case of non-refundable options, these unfortunately cannot be refunded. If the ceremony is cancelled within 14 days of the event date, the celebrant may refund fees paid by you (except the deposit), depending on the circumstances. A refund in this regard is at the discretion of the celebrant.</ListItem>
                        <ListItem>Cancellation by celebrant: In the event that the celebrant is unable to attend an event, 100% of funds paid by you will be refunded, including the $150 deposit.</ListItem>
                    </List>
                </Section>
            </Section>

            <Section>
                <Title>Document Recording and Storage</Title>
                <List>
                    <ListItem>The Celebrant is bound by the Code of Conduct for Marriage Celebrants.</ListItem>
                    <ListItem>Maintain appropriate facilities to interview parties and provide office facilities, including facilities for the secure storage of records;</ListItem>
                    <ListItem>Within a reasonable time before the marriage ceremony: confirm all details with the parties; and ensure the return of all personal documents belonging to the parties (unless it is necessary to keep the documents for the ceremony).</ListItem>
                    <ListItem>Much of the documents required will be completed electronically. Therefore, any electronic data held by the celebrant will be kept in secured and controlled databases with appropriate protection against viruses or other unauthorised access. The relevant hard copy files will be also secured in lockable filling cabinets.</ListItem>
                    <ListItem>If circumstances arise where a threat or implied threat of harm, damage, or violence to the Celebrant or equipment, the Celebrant reserves the right to cease providing services. If the client is able to resolve the threatening situation within 30 minutes, the Celebrant will resume services. If the situation is unable to be resolved, the Celebrant will not resume providing the service and the client will receive no refund of booking cost.</ListItem>
                </List>
            </Section>

            <Section>
                <Title>Equipment Maintenance and Servicing</Title>
                <List>
                    <ListItem>The Celebrant commits to ensuring that all equipment owned and required to perform Celebrant duties will always be in good and working order.</ListItem>
                    <ListItem>In order to prevent damage to equipment, the Celebrant reserves the right to deny persons the use of the equipment.</ListItem>
                    <ListItem>If circumstances arise where a threat or implied threat of harm, damage, or violence to the Celebrant or equipment, the Celebrant reserves the right to cease providing services. If the client is able to resolve the threatening situation within 30 minutes, the Celebrant will resume services. If the situation is unable to be resolved, the Celebrant will not resume providing the service and the client will receive no refund of booking cost.</ListItem>
                    <ListItem>Any damage to the Celebrant’s equipment during the client’s event caused by the client, client’s guests, or any other person in attendance at the event whether invited or not, will result in the cost to repair/replace the equipment to be payable by the client.</ListItem>
                </List>
            </Section>

            <Section>
                <Title>Stationery Ordering</Title>
                <List>
                    <ListItem>The Celebrant will ensure that all stationery and legal forms required to perform Celebrant duties will be kept current and up to date and in surplus supply at all times.</ListItem>
                    <ListItem>To ensure accuracy and validity, all legal forms will be ordered through the Australian Government recommended and approved supplier.</ListItem>
                </List>
            </Section>
        </PageContainer>
    );
};

export default BusinessPoliciesPage;
