import React from 'react';
import styled from 'styled-components';
import logo from '../assets/img/logo.png'; // Import your logo image

const AboutContainer = styled.div`
  background-color: #fce4ec; /* Light pink background */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Lucida;
`;

const Logo = styled.img`
  width: 200px; /* Adjust size as needed */
  height: auto;
  margin-bottom: 2rem; /* Add some space below the logo */
`;

const Content = styled.div`
  max-width: 800px;
  text-align: center;
  font-family: Lucida;
`;

const Heading = styled.h1`
  font-size: 2.5rem;
  color: #8c3e56; /* Dark pink heading */
  margin-bottom: 1.5rem;
  font-family: Lucida;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  color: #4d4d4d; /* Dark gray paragraph text */
  line-height: 1.6;
  font-family: Lucida;
`;

const AboutUsPage = () => {
  return (
    <AboutContainer>
      <Logo src={logo} alt="Logo" />
      <Content>
        <Heading>About Us</Heading>
        <Paragraph>
          Hi, I’m Vy Tran. I am a Civil Marriage Celebrant and I am here to create the most enjoyable and memorable
          ceremony with you ❤️ … After all it is your special day! – hence, I would love to get to know both of you
          and together, we will have a ceremony that truly reflect your unique relationship, values and personalities.
          I’ll forever feel so lucky and grateful to be invited into people’s lives, get to hear your stories and be
          part of one of your happiest day with such an important responsibility.
        </Paragraph>
        <Paragraph>
          Few things to get to know me:
        </Paragraph>
        <Paragraph>
          I am proud of my diverse background: French, Chinese, Cambodian and Vietnamese. I’m also married to an
          Australian Army man whose parents are from England. Therefore, I am all about inclusion and embracing diversity
          as marriage is between two people who love and are committed to each other regardless their race, religion,
          sex and gender.
        </Paragraph>
        <Paragraph>
          My background also gives me many advantages including being multilinguistic!
        </Paragraph>
        <Paragraph>
          I’m an animal lover! - We have two toy poodles and love them dearly ❤️
        </Paragraph>
        <Paragraph>
          I’m also a Critical Care Nurse and a Defence wife.
        </Paragraph>
        <Paragraph>
          I love travelling.
        </Paragraph>
        <Paragraph>
          I am available to take bookings for wedding, commitment ceremony, elopement, and renewal of vows. Please email
          me any time to see if our calendars match - If it is a ‘yes’, let’s meet for a drink and chat.
        </Paragraph>
      </Content>
    </AboutContainer>
  );
};

export default AboutUsPage;
