import React, { useState } from 'react';
import styled from 'styled-components';
import logo from '../assets/img/logo.png'; // Import your logo image
import locationsData from '../assets/data/australian-postcodes.json'; // Import the locations JSON file

const ContactContainer = styled.div`
  background-color: #fce4ec; /* Light pink background */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SuggestionList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
`;

const SuggestionItem = styled.li`
  padding: 0.5rem;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const Logo = styled.img`
  width: 200px; /* Adjust size as needed */
  height: auto;
  margin-bottom: 2rem; /* Add some space below the logo */
`;

const Form = styled.form`
  max-width: 500px;
  width: 100%;
  padding: 2rem;
  background-color: peach;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  font-size: 0.9rem;
  color: #4d4d4d; /* Dark gray paragraph text */
  margin-bottom: 0.5rem;
  display: block;
  font-family: Lucida
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  font-size: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: Lucida
`;

const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Option = styled.option`
  font-size: 1rem;
  font-family: Lucida;
`;

const Button = styled.button`
  background-color: #8c3e56; /* Dark pink button */
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #6e2a3b; /* Darker pink on hover */
  }
`;

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    service: 'Celebrant',
    date: '',
    suburb: '',
    postcode: '',
    state: '',
  });

  const [suggestions, setSuggestions] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Filter suggestions based on user input
    const filteredSuggestions = locationsData.filter((location) =>
      location.suburb.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };

  const handleSuggestionClick = (suburb, postcode, state) => {
    setFormData({
      ...formData,
      suburb,
      postcode,
      state,
    });
    setSuggestions([]); // Clear suggestions after selection
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Make POST request with formData
  };

  return (
    <ContactContainer>
      <Logo src={logo} alt="Logo" />
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="name">Name:</Label>
          <Input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Enter your name..."
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="email">Email:</Label>
          <Input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email..."
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="phone">Phone:</Label>
          <Input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Enter your phone number..."
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="service">Service:</Label>
          <Select
            id="service"
            name="service"
            value={formData.service}
            onChange={handleChange}
            required
          >
            <Option value="Celebrant">Celebrant</Option>
            {/* Add more options as needed */}
          </Select>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="date">Date of Function:</Label>
          <Input
            type="date"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="suburb">Suburb:</Label>
          <Input
            type="text"
            id="suburb"
            name="suburb"
            value={formData.suburb}
            onChange={handleChange}
            placeholder="Enter suburb..."
            required
          />
          {suggestions.length > 0 && (
            <SuggestionList>
              {suggestions.map((location, index) => (
                <SuggestionItem key={index} onClick={() => handleSuggestionClick(location.suburb, location.postcode, location.state)}>
                  {location.suburb}, {location.state} {location.postcode}
                </SuggestionItem>
              ))}
            </SuggestionList>
          )}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="postcode">Postcode:</Label>
          <Input
            type="text"
            id="postcode"
            name="postcode"
            value={formData.postcode}
            onChange={handleChange}
            placeholder="Enter postcode..."
            required
          />
        </FormGroup>
        <Button type="submit">Submit</Button>
      </Form>
    </ContactContainer>
  );
};

export default ContactPage;
