import React, { useState } from 'react';
import styled from 'styled-components';
import logo from '../assets/img/logo.png'; // Import your logo image

const FeedbackContainer = styled.div`
  background-color: #fce4ec; /* Light pink background */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Logo = styled.img`
  width: 200px; /* Adjust size as needed */
  height: auto;
  margin-bottom: 2rem; /* Add some space below the logo */
`;

const Form = styled.form`
  max-width: 500px;
  width: 100%;
  padding: 2rem;
  background-color: peach;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Label = styled.label`
  font-size: 0.9rem;
  color: #4d4d4d; /* Dark gray paragraph text */
  margin-bottom: 0.5rem;
  display: block;
  font-family: Lucida;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  font-size: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: Lucida;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 0.5rem;
  font-size: 0.8rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: Lucida;
  resize: vertical;
`;

const Select = styled.select`
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const Option = styled.option`
  font-size: 1rem;
  font-family: Lucida;
`;

const Button = styled.button`
  background-color: #8c3e56; /* Dark pink button */
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #6e2a3b; /* Darker pink on hover */
  }
`;

const FeedbackPage = () => {
  const [formData, setFormData] = useState({
    email: '',
    marriage: '',
    date: '',
    venue: '',
    availability: '',
    interviews: '',
    satisfaction: '',
    ceremony: '',
    improvement: '',
    recommendation: '',
    comments: '',
    publish: 'NO',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    // Make POST request with formData
  };

  return (
    <FeedbackContainer>
      <Logo src={logo} alt="Logo" />
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label htmlFor="email">Email address:</Label>
          <Input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter your email..."
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="marriage">For the Marriage of:</Label>
          <Input
            type="text"
            id="marriage"
            name="marriage"
            value={formData.marriage}
            onChange={handleChange}
            placeholder="Enter names..."
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="date">Date of Ceremony:</Label>
          <Input
            type="date"
            id="date"
            name="date"
            value={formData.date}
            onChange={handleChange}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="venue">Venue:</Label>
          <Input
            type="text"
            id="venue"
            name="venue"
            value={formData.venue}
            onChange={handleChange}
            placeholder="Enter venue..."
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="availability">Were you satisfied with my availability and contact?</Label>
          <TextArea
            id="availability"
            name="availability"
            value={formData.availability}
            onChange={handleChange}
            placeholder="Enter your feedback..."
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="interviews">Did you find any / all the interviews informative?</Label>
          <TextArea
            id="interviews"
            name="interviews"
            value={formData.interviews}
            onChange={handleChange}
            placeholder="Enter your feedback..."
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="satisfaction">Were your questions and needs answered to your satisfaction?</Label>
          <TextArea
            id="satisfaction"
            name="satisfaction"
            value={formData.satisfaction}
            onChange={handleChange}
            placeholder="Enter your feedback..."
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="ceremony">Was the ceremony met your expectations? How would you describe the ceremony overall?</Label>
          <TextArea
            id="ceremony"
            name="ceremony"
            value={formData.ceremony}
            onChange={handleChange}
            placeholder="Enter your feedback..."
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="improvement">How could I improve the services that I provide?</Label>
          <TextArea
            id="improvement"
            name="improvement"
            value={formData.improvement}
            onChange={handleChange}
            placeholder="Enter your suggestions..."
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="recommendation">Would you recommend my services to others?</Label>
          <TextArea
            id="recommendation"
            name="recommendation"
            value={formData.recommendation}
            onChange={handleChange}
            placeholder="Enter your feedback..."
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="comments">Please add any additional comments here:</Label>
          <TextArea
            id="comments"
            name="comments"
            value={formData.comments}
            onChange={handleChange}
            placeholder="Enter your comments..."
            required
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="publish">Would you like your comments and/or photos published on my social media platforms?</Label>
          <Select
            id="publish"
            name="publish"
            value={formData.publish}
            onChange={handleChange}
            required
          >
            <Option value="YES">YES</Option>
            <Option value="NO">NO</Option>
          </Select>
        </FormGroup>
        <Button type="submit">Submit</Button>
      </Form>
    </FeedbackContainer>
  );
};

export default FeedbackPage;
