import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NavBar from './components/NavBar';
import Home from './components/Home';
import About from './pages/about';
import Services from './pages/services';
import Contact from './pages/contact';
import './App.css';
import Footer from './components/Footer';
import BusinessPoliciesPage from './pages/terms';
import Feedback from './pages/FeedbackPage'

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/tos" element={<BusinessPoliciesPage />} />
        <Route path="/feedback" element={<Feedback />} /> 
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;